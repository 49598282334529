import React, { useEffect, useState, useRef, useMemo } from 'react'
import { Box, Container, Grid, Input, TextField, Typography, Button, Divider, ButtonGroup, Select, MenuItem, InputLabel, Image } from '@mui/material';
import image from '../../Assets/burger_img.jpg';
import axios from 'axios';
import "./EditProduct.css"
import { useNavigate } from 'react-router-dom';

const EditProduct = (props) => {
  const [isEdit, setIsEdit] = useState(false)
  const changeEditImage = useRef()
  const changeVisualImage = useRef()
  const { productID, customerID, productName, productPrice, productIngredients, mainGroup, productGroup, sameProduct } = props.product.item
  const mainGroups = props.product.mainGroups
  const subGroups = props.product.categories
  const currency = props.product.currency
  const notifyParent = props.product.notifyParent
  const [coverUpload, setCoverUpload] = useState(null)
  const [coverPreview, setCoverPreview] = useState(null)
  const productRef = useRef(null)
  const navigate = useNavigate()

  const [productImageUrl, setProductImageUrl] = useState(`url("/static/qrbites-product-images/product_image_${sameProduct}.png")`)

  const [mainDropdown, setMainDropdown] = useState(mainGroup)
  const [subDropdown, setSubDropdown] = useState(productGroup)
  const [sub, setSub] = useState(subGroups)
  const [fullCat, setFullCat] = useState([])
  const [name, setName] = useState(productName)
  const [price, setPrice] = useState(productPrice)
  const [ingredients, setIngredients] = useState(productIngredients)
  const handleMainSelectOnChange = (e) => {
    setMainDropdown(e.target.value);
    let getSubFromMainGroup = subGroups.filter(item => item.mainCategoryName === e.target.value)
    setFullCat(getSubFromMainGroup[0])
    setSub(getSubFromMainGroup[0].ProductGroups)
    setSubDropdown(getSubFromMainGroup[0].ProductGroups[0])
  };

  const changeProductImage = () => {
    productRef.current.click();
    productRef.current.value = ""
  };

  const onChangeProductImage = (e) => {
    let file = e.target.files[0]
    setCoverUpload(file)
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCoverPreview(reader.result);
        changeEditImage.current.style.backgroundImage = `url(${reader.result})`
      };
      reader.readAsDataURL(file);
    }
  }

  const handleSubSelectOnChange = (e) => {
    setSubDropdown(e.target.value);
  };

  const handleOnNameChange = (e) => {
    setName(e.target.value)
  }

  const handleOnPriceChange = (e) => {
    setPrice(e.target.value)
  }

  const handleOnIngredientsChange = (e) => {
    setIngredients(e.target.value)
  }

  const updateProduct = async (e) => {
    e.preventDefault()
    setIsEdit(false)
    try {
      const base64Data = coverPreview ? coverPreview.split(',')[1] : "";
      const body = {
        id: productID,
        name: name,
        price: price,
        ingredients: ingredients,
        main: mainDropdown,
        sub: subDropdown,
        customerID: customerID,
        sameID: sameProduct,
        image: base64Data,
        fullCat: fullCat
      };
      
      const response = await axios.post(`https://qrbites.co/api/update-product/`, JSON.stringify(body), {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
      })
      const data = await response.data
      if (response.status !== 200) {
        if (data.message && (data.message === "Invalid Token" || data.message === "Token not found")) {
          window.localStorage.removeItem("token")
          navigate("/login")
        }
        return
      }
      notifyParent(customerID)
      // changeVisualImage.current.style.backgroundImage = `url(${coverPreview})`
      setProductImageUrl(`url("/static/qrbites-product-images/product_image_${sameProduct}.png")`)
    } catch (error) {
      console.log(error)
    }
  }

  const deleteProduct = async (e) => {
    e.preventDefault()
    try {
      const body = {
        id: productID,
        customerID: customerID,
        sameID: sameProduct
      };
      const response = await axios.post(`https://qrbites.co/api/delete-product/`, JSON.stringify(body), {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
      })
      const data = await response.data
      if (response.status !== 200) {
        if (data.message && (data.message === "Invalid Token" || data.message === "Token not found")) {
          window.localStorage.removeItem("token")
          navigate("/login")
        }
        return
      }
      notifyParent(customerID)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    let getSubFromMainGroup = subGroups.filter(item => item.mainCategoryName === mainGroup)[0]
    setSub(getSubFromMainGroup.ProductGroups)
    setFullCat(getSubFromMainGroup)
  }, [])
  return (
    <div>
      <Grid sx={{
        width: "100%", bgcolor: 'white', display: 'flex', flexDirection: "column", alignContent: 'center', alignItems: 'center', justifyContent: 'end', flexWrap: "row wrap",
        flexGrow: 1
      }}>
        <Grid container justifyContent='end' sx={{ paddingBottom: "10px" }}>
          <Button variant='contained' sx={{ fontSize: "14px", fontWeight: 500, backgroundColor: "#366EF7", marginRight: "10px", marginLeft: "-5px", "&:hover": { backgroundColor: "black", color: "white" } }} onClick={() => {setIsEdit(!isEdit); }}>{!isEdit ? "Edit" : "Cancel Edit"}</Button>
          {!isEdit && <Button sx={{ border: "1px solid #366EF7", borderRadius: "20px", padding: "5px 10px", textTransform: "none", color: "black", "&:hover": { backgroundColor: "#366EF7", color: "white" } }} onClick={deleteProduct}>Delete</Button>}

          {isEdit && <Button sx={{ border: "1px solid #366EF7", borderRadius: "20px", padding: "5px 10px", textTransform: "none", color: "black", "&:hover": { backgroundColor: "#366EF7", color: "white" } }} onClick={updateProduct}>Save</Button>}
        </Grid>

        {isEdit ? <div onClick={changeProductImage} className='edit-product-img' ref={changeEditImage} item style={{
          width: "100%", height: "250px", borderRadius: "20px", backgroundPosition: "center center", backgroundSize: "cover", backgroundRepeat: "no-repeat", filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
          backgroundImage: productImageUrl
        }} ></div> :
          <div ref={changeVisualImage} item style={{
            width: "100%", height: "250px", borderRadius: "20px", backgroundPosition: "center center", backgroundSize: "cover", backgroundRepeat: "no-repeat", filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
            backgroundImage: productImageUrl
          }} ></div>}
        <Button
          variant="contained"
          style={{ display: 'none' }}
          component="label"
        ><Input type='file' id='file' onClick={e => (e.target.value = null)} ref={productRef} onChange={onChangeProductImage}></Input></Button>

        <Grid container sx={{ width: "100%", display: "flex", p: "20px 5px 0 5px" }}>
          <Grid item sx={{ alignSelf: "flex-start", width: "50%" }}>
            {!isEdit ?
              <Typography variant="h1" component="h2" sx={{ WebkitTextStroke: "0.5px black" }}>{name}</Typography>
              :
              <>
                Name: <Input value={name} onChange={handleOnNameChange}></Input>
              </>
            }
          </Grid>
          <Grid item sx={{ alignSelf: "flex-end", width: "50%", textAlign: "right" }}>
            {!isEdit ?
              <Typography variant="h1" component="h2" >{price}{currency}</Typography>
              :
              <>
                Price: <Input value={price} onChange={handleOnPriceChange}></Input>
              </>
            }
          </Grid>
        </Grid>

        <Grid item sx={{ alignSelf: "flex-end", width: "100%", p: "20px 5px" }}>
          {!isEdit ?
            <Typography variant="h4" >{ingredients}</Typography>
            :
            <>
              Ingredients: <Input fullWidth value={ingredients} onChange={handleOnIngredientsChange}></Input>
            </>
          }
        </Grid>

        <Grid container sx={{ width: "100%", display: "flex" }}>
          <Grid item sx={{ alignSelf: "flex-start", width: "50%" }}>
            {isEdit &&
              <>
                Main group: &nbsp;
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={mainDropdown}
                  label="main"
                  onChange={handleMainSelectOnChange}
                >
                  {mainGroups.map((item) => {
                    if (item.ProductGroups) {
                      return (
                        <MenuItem value={item.mainCategoryName}>{item.mainCategoryName}</MenuItem>
                      )
                    }

                  })
                  }
                </Select>
              </>
            }
          </Grid>
          <Grid item sx={{ alignSelf: "flex-end", width: "50%" }}>
            {isEdit &&
              <>
                Sub group: &nbsp;
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={subDropdown}
                  label="product"
                  onChange={handleSubSelectOnChange}
                >
                  {sub?.map((item) => {
                    return (
                      <MenuItem value={item}>{item}</MenuItem>
                    )
                  })
                  }
                </Select>
              </>
            }
            <Grid />
          </Grid>
        </Grid>
        <Divider orientation="horizontal" sx={{ mt: "10px", mb: "25px", width: "100%", fontWeight: 300, borderColor: "#DEDEDE" }} />
      </Grid>

    </div>
  )
}


export default EditProduct


