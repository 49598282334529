import { Grid, Typography } from '@mui/material'
import React from 'react'

const Footer = () => {
  return (
    <>
    <div style={{height: "30px", width: "100%", backgroundColor: "white"}}>

    </div>
      <Grid sx={{ width: "100%", bgcolor: 'black', display: 'flex', flexDirection: "column", alignContent: 'center', alignItems: 'center', justifyContent: 'center', flexWrap: "row wrap", flexGrow: 1, padding: '10px' }}>
        <Typography variant="body1" component="text" sx={{ color: "white" }}>Powered by <span><a style={{ color: 'white' }} href='https://codelii.com'>Codelii</a></span></Typography>
      </Grid>
    </>
  )
}

export default Footer
