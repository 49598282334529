import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#DF2935",
      secondary: "#3772FF",
    },
  },
  typography: {
    fontFamily: "Comfortaa",
    h1: {
        fontSize: "22px", // Main category cards - title
        fontWeight: 900,
    },
    h2: { 
      fontSize: "20px", // Single product - heading
      fontWeight: 800,
    },
    h3: {
      fontSize: "17px", // Single page - category title
      fontWeight: 800,
    },
    h4: {
      fontSize: "16px", // Category tabs
      fontWeight: 700,
    },
    body1: {
      fontSize: "12px", // ingredients
      fontWeight: 500,
    },
    body2: {
      fontSize: "1.2rem",
      fontWeight: 500,
    },
  },
  shape: {
    borderRadius: 20,
  },


  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "contained" },
          style: {
            fontSize: "18px",
            fontWeight: 800,
            color: "white",
            p: "13px 21px",
            borderRadius: 100,
            boxShadow: "none",
            textTransform: 'none',
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            fontSize: "1.8rem",
            fontWeight: 500,
            padding: "0.7rem 4.7rem",
          },
        },
      ],
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& label": {
            color: "black",
          },
          "& label.Mui-focused": {
            color: "#000",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "E7E8E6",
              borderRadius: "20px",
              height:"50px",
              borderWidth: "1px",
              
            },
            "&:hover fieldset": {
              borderColor: "black",
              borderRadius: "20px",
              borderWidth: "1px",
            },
            "&.Mui-focused fieldset": {
              borderColor: "black",
              borderRadius: "20px",
              borderWidth: "1px",

            },
          },
        },
      },
    },
  },
});