import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import './App.css';
import FinalMenu from './Pages/FinalMenu/FinalMenu';
import FinalMenuCategories from './Pages/FinalMenuCategories/FinalMenuCategories';
import FinalProducts from './Pages/FinalProducts/FinalProducts';
import Login from './Pages/Login/Login';
import CheckRoute from './Pages/Login/CheckRoute';
import PrivateRoute from './Pages/Login/PrivateRoute';
import Dashboard from './Pages/Dashboard/Dashboard';
import NotFound from './Pages/Not Found/NotFound';
import { RedirectMain } from './Components/RedirectMain';

function App() {
  const RemoveTrailingSlash = ({...rest}) => {
    const location = useLocation()

    // If the last character of the url is '/'
    if (location.pathname.match('/.*/$')) {
        return <Navigate replace {...rest} to={{
            pathname: location.pathname.replace(/\/+$/, ""),
            search: location.search
        }}/>
    } else return null
}
  return (
    <ThemeProvider theme={theme}>
    <Router>
    <RemoveTrailingSlash/>

      <Routes>
        <Route exact path="/" element={<RedirectMain/>} />
        <Route exact path=":id" element={<FinalMenu />} />
        <Route exact path=":id/:id" element={<FinalMenuCategories />} />
        <Route exact path=":id/:id/:id" element={<FinalMenuCategories />} />
        <Route exact path="/not-found" element={<NotFound />} />

        <Route exact path='/dashboard' element={<PrivateRoute/>}>
          <Route exact path='/dashboard' element={<Dashboard/>}/>
        </Route>

        <Route exact path='/login' element={<CheckRoute/>}>
          <Route exact path='/login' element={<Login/>}/>
        </Route>

      </Routes>
    </Router>
    </ThemeProvider>
  );
}

export default App;
