import React, { useEffect, useState } from 'react'
import axios from 'axios'

import '../../App.css';

import '../FinalMenu/FinalMenu.css';
import { Box, Container, Grid, Input, TextField, Typography, Button, Divider, CircularProgress, LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { sizing } from '@mui/system';

import MainCategoryCard from '../../Components/MainCategoryCard/MainCategoryCard';
import SingleProduct from '../../Components/SingleProduct/SingleProduct';
import Footer from '../../Components/Footer/Footer';

import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import WifiIcon from '@mui/icons-material/Wifi';
import InstaIcon from '../../Assets/insta.svg';
import FbIcon from '../../Assets/fb.svg';


import "@fontsource/syne";
import "@fontsource/syne/700.css"
import "@fontsource/open-sans";

import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

import logo from '../../Assets/logo.jpg';
import background from '../../Assets/Rectangle_27.jpg';
import { Link } from 'react-router-dom';


import CyrillicToLatin from '../../Functions/CyrillicToLatin/CyrillicToLatin';
import RestaurantInfo from '../../Components/RestauranInfo/RestaurantInfo';



const SearchBar = ({ setSearchQuery }) => (
  <form style={{ border: "1px #959595 solid", borderRadius: "50px", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "1.5px 8px" }}>
    <TextField
      component="h6"
      id="search-bar"
      className="text"
      onInput={(e) => {
        setSearchQuery(e.target.value);
      }}
      variant="outlined"
      placeholder="Search..."
      sx={{
        width: "80%", "& input": {
          color: "black",
          fontSize: "1rem"
        }
      }}

    />
    <Button variant="contained" sx={{ color: "white", backgroundColor: "black" }}>Пребарај</Button>
  </form>
);

const filterData = (query, data) => {
  if (!query) {
    return data;
  } else {
    return data.filter((d) => d.toLowerCase().includes(query));
  }
};


const options = ['`url(${image})`', 'EN', 'ALB'];

const data = [
  "Paris",
  "London",
  "New York",
  "Tokyo",
  "Berlin",
  "Buenos Aires",
  "Cairo",
  "Canberra",
  "Rio de Janeiro",
  "Dublin"
];







// =================    FINAL MENU      ========================
const FinalMenuCategories = () => {

  // ====  GETTING URL  =======

  var array = window.location.pathname.split("/");

  var filteringUrl = array.filter(function (el) {
    return el != "";
  });


  const filteredURL = filteringUrl[0]

  // ====  FETCHING USER  =======

  const [customer, setCustomer] = useState([])
  const [mainCategories, setMainCategories] = useState([])


  useEffect(() => {
    const fetchAllCustomers = async () => {
      try {
        const getCustomer = await axios.get(`https://qrbites.co/api/customers/url/${filteredURL}`);
        if (getCustomer.data.error) {
          return
        }
        const customer = getCustomer.data[0]

        const getCategories = await axios.get(`https://qrbites.co/api/customers/main-categories/id/${customer.customerID}`);
        const categories = getCategories.data
        setCustomer(customer);
        setMainCategories(categories);
        setIsLoading(false)
      } catch (err) {
        console.log(err)
      }
    }
    fetchAllCustomers()
  }, [])




  let selectedCategory = []
  const lastUrl = window.location.pathname.split("/").pop().replace("%20", "-")


  for (let i = 0; i < mainCategories.length; i++) {
    if (mainCategories[i]?.mainCategoryURL == decodeURI(lastUrl)) {
      selectedCategory = mainCategories[i]
    }
  }



  // ====  ||  =======

  const [searchQuery, setSearchQuery] = useState("");
  const dataFiltered = filterData(searchQuery, data);


  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [loading, setIsLoading] = useState(true)


  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };


  if (!customer) {
    return
  }

  // ===================     JS CODE     =====================
  return (
    <>
    <Container maxWidth="sm" sx={{ p: 0, position: "relative", display: loading ? "none" : "block" }}>

      <RestaurantInfo />
      {/* BOX FOR BACKGROUND  */}
      {/* <div style={{backgroundColor: "#fff"}}>
    <div>
          {
            
              selectedCategory?.ProductGroups?.map((item) => <MainCategoryCard name={item} url={item} />)
          }
    </div> */}

      {/* </div> */}

      <Footer />
    </Container>
    {loading && <Grid container style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }} direction="column" alignItems="center">

        <img src={"https://qrbites.co/wp-content/uploads/2023/05/Asset-1@3x.png"} alt="Logo" style={{
          width: 200,
        }} />
        <div style={{
          position: 'relative',
          width: 45,
          height: 45
        }}>
          <CircularProgress style={{color: "#ff0000"}} size={43} thickness={2}/>
          <div style={{
            position: 'absolute',
            top: "50%",
            left: "49%",
            transform: 'translate(-50%, -49%)'
          }}>
            <CircularProgress style={{animationDirection: "reverse", color: '#346ef7'}} size={50} thickness={2}/>
          </div>
        </div>

        <hr />
        <p className='loading-elipsis'>
        Opening categories for {filteredURL}
        </p>

      </Grid>}
    </>
  );
}

export default FinalMenuCategories

