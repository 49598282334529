import React, { useEffect, useState } from 'react'
import axios from 'axios'
import '../../App.css';
import './FinalMenu.css';
import { CircularProgress, Grid } from '@mui/material';
import "@fontsource/syne";
import "@fontsource/syne/700.css"
import "@fontsource/open-sans";
import { useNavigate } from 'react-router-dom';

const FinalMenu = () => {
  let array = window.location.pathname.split("/");

  let filteringUrl = array.filter(function (el) {
    return el != "";
  });


  const filteredURL = filteringUrl[0]
  const navigate = useNavigate()
  useEffect(() => {
    const fetchAllCustomers = async () => {
      try {
        const getCustomer = await axios.get(`https://qrbites.co/api/customers/url/${filteredURL}`);
        if (getCustomer.data.error) {
          navigate("/not-found");
          return;
        }
        const customer = getCustomer.data[0];

        const getCategories = await axios.get(`https://qrbites.co/api/customers/main-categories/id/${customer.customerID}`);
        const categories = getCategories.data;

        let array = [];
        if (!localStorage.getItem("language")) {
          let lang = customer.customerLanguages.includes("EN") ? "EN" : customer.customerLanguages[0];
          localStorage.setItem("language", lang);
        } else if (!customer.customerLanguages.includes(localStorage.getItem("language"))) {
          localStorage.setItem("language", customer.customerLanguages[0])
        }

        categories.forEach(item => {
          if (item.mainCategoryLanguages === localStorage.getItem("language")) {
            array.push(item);
          }
        });
        for (let i = 0; i < array.length; i++) {
          if (!window.location.pathname.includes(array[i])) {
            navigate(array[i].mainCategoryName);
            break;
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchAllCustomers()
  }, [])

  return (<Grid container style={{
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }} direction="column" alignItems="center">

    <img src={"https://qrbites.co/wp-content/uploads/2023/05/Asset-1@3x.png"} alt="Logo" style={{
      width: 200,
    }} />
    <div style={{
      position: 'relative',
      width: 45,
      height: 45
    }}>
      <CircularProgress style={{ color: "#ff0000" }} size={43} thickness={2} />
      <div style={{
        position: 'absolute',
        top: "50%",
        left: "49%",
        transform: 'translate(-50%, -49%)'
      }}>
        <CircularProgress style={{ animationDirection: "reverse", color: '#346ef7' }} size={50} thickness={2} />
      </div>
    </div>

    <hr />
    <p className='loading-elipsis'>
      Opening menu for {filteredURL}
    </p>

  </Grid>
  )
}

export default FinalMenu
