import React, { useEffect, useState, useRef } from 'react'
import { currencies } from '../../Pages/Dashboard/currencies'
import axios from 'axios'

import '../../App.css';

import './../../Pages/FinalMenu/FinalMenu.css';
import { Box, Container, Grid, Input, TextField, Typography, Button, Divider } from '@mui/material';
import { sizing } from '@mui/system';

import MainCategoryCard from '../../Components/MainCategoryCard/MainCategoryCard';
import SingleProduct from '../../Components/SingleProduct/SingleProduct';
import Footer from '../../Components/Footer/Footer';
import SearchIcon from '@mui/icons-material/Search';

import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import WifiIcon from '@mui/icons-material/Wifi';
// import InstagramIcon from '@mui/icons-material/Instagram';
import { ReactComponent as FacebookIcon } from "../../Assets/fb.svg"
import { ReactComponent as InstagramIcon } from "../../Assets/insta.svg"
import InstaIcon from '../../Assets/insta.svg';
import FbIcon from '../../Assets/fb.svg';
import CallIcon from '../../Assets/phone_call.svg';
import WifiIcons from '../../Assets/wifi.svg';
// import SearchIcon from '../../Assets/search.svg'


import "@fontsource/syne";
import "@fontsource/syne/700.css"
import "@fontsource/open-sans";

import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';


import IconButton from "@mui/material/IconButton";

import logo from '../../Assets/logo.jpg';
import background from '../../Assets/Rectangle_27.jpg';
import { Link, useNavigate } from 'react-router-dom';

import CyrillicToLatin from '../../Functions/CyrillicToLatin/CyrillicToLatin';

import ReactFlagsSelect from "react-flags-select";
import { useHistory } from "react-router-dom"


const SearchBar = ({ setSearchQuery }) => (

  <form style={{ border: "1px #1B1C1D solid", borderRadius: "50px", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "5px" }}>
    <TextField

      component="h6"
      id="search-bar"
      variant="standard"
      className="text"
      onInput={(e) => {
        setSearchQuery(e.target.value);
      }}
      placeholder="Search..."
      sx={{
        width: "80%", "& input": {
          color: "black",
          marginLeft: "10px",
          fontSize: "1rem",
        }
      }}
      InputProps={{
        disableUnderline: true,
      }}

    />
    <SearchIcon style={{ width: "25px", padding: 0, margin: 0, color: "#1B1C1D", margin: "10px 12px" }} />
  </form>
);











const RestaurantInfo = () => {
  const navigate = useNavigate();

  // ====  GETTING URL  =======

  var array = window.location.pathname.split("/");

  var filteringUrl = array.filter(function (el) {
    return el != "";
  });


  const filteredURL = filteringUrl[0]
  const lastUrl = window.location.pathname.split("/").pop().replace("%20", "-")
  const prevUrl = window.location.pathname.split("/")[2] ? window.location.pathname.split("/")[2].replace("%20", "-") : ""
  const nextUrl = window.location.pathname.split("/")[3] ? window.location.pathname.split("/")[3].replace("%20", "-") : ""


  // ====  FETCHING USER  =======

  const [customer, setCustomer] = useState([])
  const [mainCategories, setMainCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [options, setOptions] = useState([])
  const [select, setSelect] = useState("SE");
  const onSelect = (code) => setSelect(code);
  const [products, setProducts] = useState([])
  const [tempMainCategories, setTempMainCategories] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])
  const [isDone, setIsDone] = useState(false)
  const [allProducts, setAllProducts] = useState([])
  const [logoPreview, setLogoPreview] = useState([])
  const [coverPreview, setCoverPreview] = useState([])
  const [groupImages, setGroupImages] = useState([])
  const [currency, setCurrency] = useState("")

  const logoRef = useRef(null)
  const coverRef = useRef(null)

  const [showProducts, setShowProducts] = useState(false)
  let dataQ = [];

  const filterData = (query, dataQ) => {
    if (!query) {
      return products;
    } else {
      return products.filter((d) =>
        d.productName.toLowerCase().includes(query) ||
        d.productName.toUpperCase().includes(query) ||
        d.productName.includes(query) ||
        d.productIngredients.toLowerCase().includes(query) ||
        d.productIngredients.toUpperCase().includes(query) ||
        d.productIngredients.includes(query));

    }
  };

  const changeSubBar = (name) => {
    window.history.replaceState({}, null, "https://app.qrbites.co/" + customer.customerURL + "/" + name)
    let newProducts = mainCategories.filter(item => item.mainCategoryLanguages === localStorage.getItem("language"))
    setTempMainCategories(newProducts)
    if (newProducts.filter(item => item.mainCategoryName === decodeURI(name))[0]) {
      setSubCategories(newProducts.filter(item => item.mainCategoryName === decodeURI(name))[0].ProductGroups)
    }
    let productFiltered = allProducts.filter(item => item.productLanguage === localStorage.getItem("language"))
    setProducts(productFiltered)
    setShowProducts(false)
  }

  useEffect(() => {

    const fetchAllCustomers = async () => {
      try {
        const getCustomer = await axios.get(`https://qrbites.co/api/customers/url/${filteredURL}`);
        if (getCustomer.data.error) {
          return;
        }
        const customer = getCustomer.data[0];

        const [productImages, getCategories, getProducts] = await Promise.all([
          axios.get(`https://qrbites.co/api/group-images/url/${customer.customerID}`),
          axios.get(`https://qrbites.co/api/customers/main-categories/id/${customer.customerID}`),
          axios.get(`https://qrbites.co/api/customers/main-categories/products/id/${customer.customerID}`),
        ]);

        const imagesGroup = await productImages.data
        const categories = await getCategories.data
        const gottenProducts = await getProducts.data

        setGroupImages(imagesGroup);
        setAllProducts(gottenProducts);
        setCustomer(customer);
        setMainCategories(categories);
        setOptions(customer.customerLanguages);
        setCurrency(customer.currency);

        let tempArray = []
        if (!localStorage.getItem("language")) {
          let lang = customer.customerLanguages.includes("EN") ? "EN" : customer.customerLanguages[0]
          setSelectedIndex(options.indexOf(lang))
          localStorage.setItem("language", lang)
        } else if (!customer.customerLanguages.includes(localStorage.getItem("language"))) {
          localStorage.setItem("language", customer.customerLanguages[0])
          setSelectedIndex(customer.customerLanguages.indexOf(localStorage.getItem("language")))
        } else {
          setSelectedIndex(customer.customerLanguages.indexOf(localStorage.getItem("language")))
        }
        categories.forEach(item => {
          if (item.mainCategoryLanguages === localStorage.getItem("language")) {
            tempArray.push(item)
          }
        })

        setTempMainCategories(tempArray)
        if (tempArray.filter(item => item.mainCategoryName === decodeURI(prevUrl))[0]) {
          setSubCategories(tempArray.filter(item => item.mainCategoryName === decodeURI(prevUrl))[0].ProductGroups)
        }
        let productFiltered = gottenProducts.filter(item => item.productLanguage === localStorage.getItem("language"))
        setProducts(productFiltered)

        if (nextUrl) {
          let product = productFiltered.filter(v => v.productGroup === decodeURI(nextUrl) && v.productLanguage === localStorage.getItem("language"))
          setSelectedProducts(product)
          setShowProducts(true)
        }

        let shouldNavigate = true
        for (let i = 0; i < tempArray.length; i++) {
          if (prevUrl === tempArray[i].mainCategoryName) {
            shouldNavigate = false
          }
        }
        if (shouldNavigate) {
          navigate("/" + customer.customerURL + "/" + tempArray[0].mainCategoryName)
          let newProducts = tempArray.filter(item => item.mainCategoryLanguages === localStorage.getItem("language"))
          setTempMainCategories(newProducts)
          if (newProducts.filter(item => item.mainCategoryName === decodeURI(tempArray[0].mainCategoryName))[0]) {
            setSubCategories(newProducts.filter(item => item.mainCategoryName === decodeURI(tempArray[0].mainCategoryName))[0].ProductGroups)
          }
          setProducts(productFiltered)
          setShowProducts(false)
          return
        }

        let shouldNavigateSub = true
        let againFilter = tempArray.filter(item => item.mainCategoryName === decodeURI(prevUrl))[0].ProductGroups
        for (let i = 0; i < againFilter.length; i++) {
          if (nextUrl === againFilter[i]) {
            shouldNavigateSub = false
          }
        }
        if (shouldNavigateSub && nextUrl) {
          navigate("/" + customer.customerURL + "/" + tempArray[0].mainCategoryName + "/" + againFilter[0])
          let product = productFiltered.filter(v => v.productGroup === decodeURI(againFilter[0]) && v.productLanguage === localStorage.getItem("language"))
          setSelectedProducts(product)
          setShowProducts(true)
        }
      } catch (err) {
        console.log(err)
      }
    }
    fetchAllCustomers()
  }, [])



  // ====  ||  =======

  const [searchQuery, setSearchQuery] = useState("");
  const dataFiltered = filterData(searchQuery, dataQ);


  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);


  const handleMenuItemClick = (event, index) => {
    let prevProduct = tempMainCategories.filter(item => item.mainCategoryName === decodeURI(prevUrl))
    let getPrevIndex = tempMainCategories.indexOf(prevProduct[0])

    let subGroupInex = prevProduct[0].ProductGroups.indexOf(decodeURI(nextUrl))

    setSelectedIndex(index);
    localStorage.setItem("language", options[index])

    let array = []
    mainCategories.forEach(item => {
      if (item.mainCategoryLanguages === options[index]) {
        array.push(item)
      }
    })
    setTempMainCategories(array)

    if (!showProducts) {
      window.history.replaceState({}, null, "/" + customer.customerURL + "/" + array[getPrevIndex].mainCategoryName);
      setProducts(false)
    } else {
      window.history.replaceState({}, null, "/" + customer.customerURL + "/" + array[getPrevIndex].mainCategoryName + "/" + array[getPrevIndex].ProductGroups[subGroupInex]);
      setShowProducts(true)
    }

    let productFiltered = allProducts.filter(item => item.productLanguage === localStorage.getItem("language"))
    setProducts(productFiltered)
    setOpen(false);

    let product = allProducts.filter(v => v.productGroup === array[getPrevIndex].ProductGroups[subGroupInex] && v.productLanguage === localStorage.getItem("language"))
    setSelectedProducts(product)
    setSubCategories(array[getPrevIndex].ProductGroups)

  };

  const handleChange = (e) => {
    console.log(e)
  }

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleToggle = (e) => {
    setOpen((prevOpen) => !prevOpen);
  };

  const openProductsForUse = (url, item) => {
    window.history.replaceState({}, null, url)

    let product = products.filter(v => v.productGroup === decodeURI(item) && v.productLanguage === localStorage.getItem("language"))
    setSelectedProducts(product)
    setShowProducts(true)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };


  if (!customer) {
    return
  }


  // ===================     JS CODE     =====================
  return (
    <>

      {/* LANGUAGES */}
      {options.length !== 1 &&

        <div >
          <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button" sx={{ borderRadius: "50%" }} style={{
            position: "absolute", float: "right", right: "50px", top: "30px"
          }}>

            <Button
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
              sx={{ backgroundColor: "white", color: "black", borderRadius: "50%", height: "50px", width: "50px" }}
            >
              <div style={{
                display: "inherit",
                aspectRatio: "1"
              }}>
                <img style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  objectFit: "cover"
                }} src={options[selectedIndex] === "EN" ? `${"https://cdn.jsdelivr.net/gh/lipis/flag-icons/flags/4x3/"}gb.${"svg"}` : `${"https://cdn.jsdelivr.net/gh/lipis/flag-icons/flags/4x3/"}${options[selectedIndex]?.toLowerCase()}.${"svg"}`} alt={options[selectedIndex]} />

              </div>


              {/* <ReactFlagsSelect
                  selected={select}
                  onSelect={onSelect}
                  countries={options}
                  showOptionLabel={false}
              /> */}


            </Button>
          </ButtonGroup>
          <Popper
            sx={{
              zIndex: 1,
            }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      {options.map((option, index) => {
                        if (option !== options[selectedIndex]) {
                          return (
                            <MenuItem
                              key={option}
                              // disabled={index === 2}
                              selected={index === selectedIndex}
                              onClick={(event) => handleMenuItemClick(event, index)}
                            >
                              <img src={option === "EN" ? `${"https://cdn.jsdelivr.net/gh/lipis/flag-icons/flags/4x3/"}gb.${"svg"}` : `${"https://cdn.jsdelivr.net/gh/lipis/flag-icons/flags/4x3/"}${option?.toLowerCase()}.${"svg"}`} width='20px' style={{ maxWidth: "20px" }} alt={options[selectedIndex]} />
                            </MenuItem>
                          )
                        }
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>}





      <Box sx={{
        backgroundImage: `url("/static/qrbites-product-images/customer_cover_${customer.customerURL}_${customer.customerID}.png")`
          ||
          `url("https://qrbites.co/wp-content/uploads/2023/06/DefaultImage.png")`,

        backgroundPosition: "center center", backgroundSize: "cover", backgroundRepeat: "no-repeat", bgcolor: 'primary.secondary', height: '200px'
      }}>

        <div style={{ backgroundColor: "rgba(0, 0, 0, 0.4)", width: "100%", height: "100%", textAlign: 'center', display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
          <img className="logo-dashboard" src={`/static/qrbites-product-images/customer_logo_${customer.customerURL}_${customer.customerID}.png` || 'https://qrbites.co/wp-content/uploads/2023/06/DefaultImage.png'} style={{ borderRadius: '100%', marginTop: '-30px' }} />
        </div>
      </Box>

      <Box sx={{ bgcolor: 'white', display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', borderRadius: '15px 15px 0 0', marginTop: '-30px', height: '40px' }} />


      <Grid container sx={{ bgcolor: 'white', display: 'flex', alignContent: 'end', alignItems: 'end', justifyContent: 'center', borderRadius: '15px 15px 0 0', marginTop: '-30px', flexGrow: 1, padding: '0px 30px' }} >
        <Grid xs={8} item sx={{ bgcolor: 'white' }}>
          <Typography variant='h1' style={{ fontFamily: 'comfortaa', fontWeight: '700', padding: "20px 0 0 0", margin: 0 }}>{customer?.customerName}</Typography>
        </Grid>
        <Grid xs={4} item sx={{ bgcolor: 'white', textAlign: 'right', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
          {customer.customerInstagram && <a href={`http://www.${customer.customerInstagram}`} style={customer?.customerInstagram ? { height: "25px" } : { display: 'none' }}>
            <InstagramIcon />
          </a>}

          {customer.customerFacebook && <a href={`http://www.${customer.customerFacebook}`} style={customer?.customerInstagram ? { paddingLeft: '10px', height: "25px" } : { display: 'none' }}>
            <FacebookIcon />
          </a>}
        </Grid>
      </Grid>

      <Grid container gap={2} sx={{ bgcolor: 'white', display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'left', flexGrow: 1, padding: '0 30px' }} >
        {customer.customerPhoneNumber && <Grid sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', }}>
          {/* <LocalPhoneIcon sx={{ color: '#959595', fontSize: 19, paddingRight: "5px"  }} /> */}
          <img src={CallIcon} style={{ paddingRight: "10px", width: "18px", paddingTop: "2.5px" }} />
          <h3 style={{ fontFamily: 'Open Sans', fontWeight: '500', fontSize: '16px', opacity: 0.8, color: 'gray' }}>
            {customer.customerPhoneNumber}
          </h3>
        </Grid>}

        {customer.customerWifiPassword && <Grid sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', }}>
          {/* <WifiIcon sx={{ color: '#959595', fontSize: 20, paddingRight: "5px" }} /> */}
          <img src={WifiIcons} style={{ paddingRight: "10px", width: "20px" }} />
          <h3 style={{ fontFamily: 'Open Sans', fontWeight: '500', fontSize: '16px', opacity: 0.8, color: 'gray' }}>
            {customer.customerWifiPassword}
          </h3>
        </Grid>}

      </Grid>



      <Grid container gap={2} sx={{ bgcolor: 'white', display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'left', flexGrow: 1 }} >
        <div
          style={{
            display: "flex",
            alignSelf: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: "10px 20px",
            width: "100%"
          }}
        >
          <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
          <div style={{ padding: 3, width: "100%" }}>
            {document.getElementById('search-bar')?.value == 0 ? <></> : dataFiltered.map((item) => (
              <div
                className="text"
                style={{
                  padding: 5,
                  borderColor: "transparent",
                  justifyContent: "normal",
                  fontSize: 20,
                  color: "black",
                  margin: 1,
                  BorderColor: "green",
                  borderWidth: "10px"
                }}
                key={item.id}
              >
                <SingleProduct item={{ item, currency: currencies.find(item => item.cc === customer.currency).symbol }} pading="20px" />
              </div>
            ))}
          </div>
        </div>
      </Grid>



      {!searchQuery &&
        <div style={{ backgroundColor: "#fff" }}>
          <div className="scrollmenu">
            {
              tempMainCategories?.map((item) => {
                return (
                  <a key={item.mainCategoriesID} onClick={() => changeSubBar(item.mainCategoryName)}
                    className={decodeURIComponent(window.location.pathname.split("/")[2]) == item.mainCategoryName ? "active" : null}>
                    {item.mainCategoryName}</a>
                )
              })

            }
          </div>
        </div>
      }

      {!searchQuery && !showProducts &&
        <div style={{ backgroundColor: "#fff" }}>
          <div>
            {
              subCategories[0] ?
                subCategories.map((item) => {
                  let correctImage = groupImages.find(it => it.groupName === item)
                  return (
                    <div key={item}>
                      <MainCategoryCard openProductsForUse={openProductsForUse} products={allProducts} currencies={currencies} customer={customer} image={correctImage} name={item} url={item} />
                    </div>
                  )
                })
                :
                <div style={{ display: "flex", height: "40vh", alignItems: "center", justifyContent: "center" }}>
                  <Typography variant='h2' color='#959595'>This list is empty.</Typography>
                </div>
            }
          </div>
        </div>
      }
      {!searchQuery && showProducts &&
        <div style={{ backgroundColor: "#fff", paddingTop: "10px" }}>
          <div>

            {selectedProducts.length !== 0 ?
              selectedProducts.map((item) => {
                return (
                  <SingleProduct
                    key={item.productID}
                    item={{
                      item,
                      currency: currencies.find((currency) => currency.cc === customer.currency).symbol,
                    }}
                  />
                );
              })
              :
              <div
                style={{
                  display: "flex",
                  height: "40vh",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h2" color="#959595">
                  This list is empty.
                </Typography>
              </div>
            }
          </div>
        </div>
      }

    </>
  );
}


export default RestaurantInfo
