import React, { useState, useEffect } from 'react'
import { Grid, Typography, Divider } from '@mui/material';

const SingleProduct = (props) => {
  const { productPrice, productIngredients, productName, sameProduct } = props.item.item
  const [exist, setExist] = useState(false)

  function checkImage() {
    const image = new Image();
    image.onload = function () {
      if (this.width > 0) {
        setExist(true)
      }
    }
    image.onerror = function () {
      setExist(false)
    }
    image.src = `/static/qrbites-product-images/product_image_${sameProduct}.png`;
  }

  useEffect(() => {
    checkImage()
  }, [])

  return (
    <div>
      <Grid
        sx={{
          width: "100%",
          bgcolor: 'white',
          display: 'flex',
          flexDirection: "column",
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: "row wrap",
          flexGrow: 1,
          padding: '0 30px'
        }}
      >
        {exist && (
          <Grid
            item
            sx={{
              width: "100%",
              height: "250px",
              borderRadius: "20px",
              backgroundPosition: "center center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
              backgroundImage: `url("/static/qrbites-product-images/product_image_${sameProduct}.png")`
            }}
          ></Grid>
        )}

      <Grid container sx={{ width: "100%", display: "flex", p: "20px 5px" }}>
        <Grid item sx={{ alignSelf: "flex-start", width: "70%" }}>
          <Typography variant="h2" component="h2" sx={{ WebkitTextStroke: "0.5px black" }}>{productName}</Typography>
        </Grid>
        <Grid item sx={{ alignSelf: "flex-end", width: "30%", textAlign: "right" }}>
          <Typography variant="h2" component="h2" >{productPrice}{props.item.currency}</Typography>
        </Grid>
      </Grid>

      <Grid item sx={{ alignSelf: "flex-end", width: "100%", p: "10px 5px" }}>
        <Typography variant="body1" >{productIngredients}</Typography>
      </Grid>
      <Divider orientation="horizontal" sx={{ my: "20px", width: "100%", fontWeight: 300, borderColor: "#DEDEDE" }} />
    </Grid>
    </div >
  )
}

export default SingleProduct