import React, { useEffect, useState, useRef, createElement } from 'react'
import QRCode from "qrcode"
import "./Dashboard.css"
import NavbarDashboard from '../../Components/NavbarDashboard/NavbarDashboard'
import Logo from "./../../Assets/logo.jpg"
import FastfoodIcon from '@mui/icons-material/Fastfood';
import Cover from "./../../Assets/Rectangle_27.jpg"
import InstaIcon from "./../../Assets/insta.svg"
import FbIcon from "./../../Assets/fb.svg"
import IngredientsIcon from "./../../Assets/ingredient.png"
import CafeIcon from "./../../Assets/cafe.png"
import WifiIcon from "./../../Assets/wifi_black.svg"
import PhoneIcon from "./../../Assets/phone_call_black.svg"
import RestaurantIcon from "./../../Assets/name.svg"
import UploadIcon from '@mui/icons-material/Upload';
import QRIcon from "./../../Assets/qrcode.svg"
import { Typography, Grid, Button, useMediaQuery, useTheme, ButtonGroup, Divider, Select, MenuItem, IconButton, Input, Modal, Box, FormHelperText, ImageListItem, ImageListItemBar, FormControlLabel, Checkbox, ListItemText, ListItemIcon, FormControl, InputLabel, Stack, Toolbar } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { currencies } from './currencies'
import { countryCodes } from './languages'
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import axios from "axios"
import BorderColorIcon from '@mui/icons-material/BorderColor';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import AddIcon from '@mui/icons-material/Add';
import jwtDecode from 'jwt-decode'
import SingleProduct from "../../Components/SingleProduct/SingleProduct"
import EditProduct from '../../Components/SingleProduct/EditProduct'
import { useNavigate } from 'react-router-dom'

const Dashboard = () => {
    const [customer, setCustomer] = useState({
        customerCoverImage: null,
        customerCustomSocial: null,
        customerEmail: "",
        customerFacebook: "",
        customerID: 0,
        customerInstagram: "",
        customerLanguages: "",
        customerLogo: null,
        customerMainGroups: "",
        customerName: "",
        customerPassword: "",
        customerPhoneNumber: "",
        customerQRCode: null,
        customerURL: "",
        customerWifiPassword: ""
    })
    const navigate = useNavigate()
    const [mainGroups, setMainGroups] = useState([])
    const [categories, setCategories] = useState([])
    const [selectedSubGroup, setSelectedSubGroup] = useState([])
    const [selectedProducts, setSelectedProducts] = useState([])
    const [products, setProducts] = useState([])
    const [selectedGroupButton, setSelectedGroupButton] = useState("")
    const [selectedMainButton, setSelectedMainButton] = useState("")
    const [language, setLanguage] = useState("")
    const [languages, setLanguages] = useState([])
    const [newMainGroupVal, setNewMainGroupVal] = useState("")
    const [newMainGroupUrl, setNewMainGroupUrl] = useState("")
    const [newSubGroupVal, setNewSubGroupVal] = useState("")
    const [mainGroupState, setMainGroupState] = useState(0)
    const [subGroupState, setSubGroupState] = useState(0)
    const [blaSel, setBlaSel] = useState([])
    const inputFile = useRef(null)
    const subGroupFile = useRef(null)
    const inputFileCover = useRef(null)
    const qrCodeCanvas = useRef(null)

    //add product 
    const [modalProductName, setModalProductName] = useState("")
    const [modalProductPrice, setModalProductPrice] = useState("")
    const [modalProductIngredients, setModalProductIngredients] = useState("")
    const [modalProductGroup, setModalProductGroup] = useState("")
    const [modalProductSubGroup, setModalProductSubGroup] = useState("")
    const [modalProductImage, setModalProductImage] = useState(null)
    const [currency, setCurrency] = useState("")
    const [sub, setSub] = useState([])
    const addProductRef = useRef(null)

    const onChangeModalProductImage = (e) => {
        let file = e.target.files[0]
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setModalProductImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    }

    const modalProductGroupOnChange = (e) => {
        setModalProductGroup(e.target.value);

        let getSubFromMainGroup = categories.filter(item => item.mainCategoryName === e.target.value.mainCategoryName)[0].ProductGroups
        setSub(getSubFromMainGroup)
        setModalProductSubGroup(getSubFromMainGroup[0])
    };

    const submitAddNewProduct = async () => {
        try {
            const base64Data = modalProductImage ? modalProductImage.split(',')[1] : "";
            const body = {
                name: modalProductName,
                price: modalProductPrice,
                ingredients: modalProductIngredients,
                mainGroup: categories.filter(item => item.mainCategoryURL === modalProductGroup.mainCategoryURL),
                subGroup: sub.indexOf(modalProductSubGroup),
                image: base64Data,
                customerID: customer.customerID,
            };
            const response = await axios.post(`https://qrbites.co/api/add-new-product/`, JSON.stringify(body), {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            })
            const data = await response.data
            if (response.status !== 200) {
                if (data.message && (data.message === "Invalid Token" || data.message === "Token not found")) {
                    window.localStorage.removeItem("token")
                    navigate("/login")
                }
                return
            }
            const getProducts = await axios.get(`https://qrbites.co/api/customers/main-categories/products/id/${customer.customerID}`);
            const products = getProducts.data

            setProducts(products)

            let product = products.filter(v => v.productGroup === sub[sub.indexOf(modalProductSubGroup)] && v.productLanguage === language)
            setSelectedProducts(product)

            addProductRef.current.value = ""
            setAddProductModal(false)
        } catch (error) {
            console.log(error)
        }
    }

    function download(url) {
        const a = document.createElement('a')
        a.href = url
        a.download = url.split('/').pop()
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }

    //

    const [logoUpload, setLogoUpload] = useState(null)
    const [logoPreview, setLogoPreview] = useState(null)

    const [subGroupLogo, setSubGroupLogo] = useState(null)
    const [subLogoPreview, setSubLogoPreview] = useState(null)

    const [coverUpload, setCoverUpload] = useState(null)
    const [coverPreview, setCoverPreview] = useState(null)

    const [qrCodeCustomer, setQrCodeCustomer] = useState(null)
    const [groupImages, setGroupImages] = useState([])
    const [openQrCode, setOpenQrCode] = useState(false);
    const handleOpenQrCode = () => {
        setOpenQrCode(true);
    }

    const handleCloseQrCode = () => setOpenQrCode(false);

    const generateQrCode = async () => {
        try {
            QRCode.toCanvas(qrCodeCanvas.current, "app.qrbites.co/" + customer.customerURL, { scale: "20" }, function (error) {
                if (error) {
                    console.log(error)
                    return
                }
            })
            let core = qrCodeCanvas.current.toDataURL("image/png")
            setQrCodeCustomer(core)
            let replaceIt = core.replace(/^data:image\/?[A-z]*;base64,/, "")
            const body = {
                id: customer.customerID,
                qrCode: replaceIt
            };
            const response = await axios.post(`https://qrbites.co/api/create-qr-code/`, JSON.stringify(body), {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            })
            const data = await response.data
            if (response.status !== 200) {
                if (data.message && (data.message === "Invalid Token" || data.message === "Token not found")) {
                    window.localStorage.removeItem("token")
                    navigate("/login")
                }
                return
            }
        } catch (error) {
            console.log(error)
        }
    }


    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setCustomer({ ...customer, [name]: value });
    };


    const [openMainGroupModal, setMainGroupModal] = useState(false);
    const handleOpenMainGroupModal = () => setMainGroupModal(true);
    const handleCloseMainGroupModal = () => setMainGroupModal(false);

    const [openSubGroupModal, setSubGroupModal] = useState(false);
    const handleOpenSubGroupModal = () => {
        setSubGroupModal(true);
    }
    const handleCloseSubGroupModal = () => setSubGroupModal(false);

    const [openAddProductModal, setAddProductModal] = useState(false);
    const handleOpenProductModal = () => setAddProductModal(true);
    const handleCloseProductModal = () => setAddProductModal(false);

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const base64Data = logoPreview ? logoPreview.split(',')[1] : "";
            const coverBase64Data = coverPreview ? coverPreview.split(',')[1] : "";

            let languageFilter = []
            let newLanguages = []
            for (let lan of selectedMultipleLanguage) {
                let found = countryCodes.find(item => item.name === lan)
                languageFilter.push(found.code)
                if (!languages.includes(found.code)) {
                    newLanguages.push(found.code)
                }
            }
            let remoteFileNameLogo = `customer_logo_${customer.customerURL}_${customer.customerID}.png`
            let remoteFileNameCover = `customer_cover_${customer.customerURL}_${customer.customerID}.png`
            const body = {
                id: customer.customerID,
                name: customer.customerName,
                phone: customer.customerPhoneNumber,
                wifi: customer.customerWifiPassword,
                instagram: customer.customerInstagram,
                facebook: customer.customerFacebook,
                languages: languageFilter.join(","),
                newLanguages: newLanguages,
                logo: base64Data,
                cover: coverBase64Data,
                currency: currency,
                remoteFileNameLogo: remoteFileNameLogo,
                remoteFileNameCover: remoteFileNameCover
            };
            const response = await axios.post(`https://qrbites.co/api/update-info/`, JSON.stringify(body), {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            })
            const data = await response.data
            if (response.status !== 200) {
                if (data.message && (data.message === "Invalid Token" || data.message === "Token not found")) {
                    window.localStorage.removeItem("token")
                    navigate("/login")
                }
                return
            }
            localStorage.setItem("token", data.token)
            // setCustomer(data.user)
            const getCategories = async () => {
                const getCategories = await axios.get(`https://qrbites.co/api/customers/main-categories/id/${data.user.customerID}`);
                const categories = getCategories.data
    
                setCategories(categories)
            }
    
            const getProducts = async () => {
                const getProducts = await axios.get(`https://qrbites.co/api/customers/main-categories/products/id/${data.user.customerID}`);
                const products = getProducts.data
    
                setProducts(products)
            }
    
            getCategories()
            getProducts()
            setCustomer(data.user)
            setLanguages(data.user.customerLanguages)
        } catch (error) {
            console.log(error)
        }
    }

    const broofa = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    const onCurrencyChange = (e) => {
        setCurrency(e.target.value)
    }

    const onChangeLanguageDropdown = (e) => {
        setLanguage(e.target.value)
        let array = []
        categories.forEach(item => {
            if (item.mainCategoryLanguages === e.target.value) {
                array.push(item)
            }
        })
        setMainGroups(array)

        let subGroup = array.filter(v => v.mainCategoryLanguages === e.target.value)
        setSelectedSubGroup(subGroup)

        let product = products.filter(v => v.productGroup === subGroup && v.productLanguage === language)
        setSelectedProducts(product)
    }

    const selectMainGroup = (item) => {
        setSelectedMainButton(item)
        let subGroup = categories.filter(v => v.mainCategoryName === item)
        setSelectedSubGroup(subGroup)
    }

    const selectProductGroup = (item) => {
        setSelectedGroupButton(item)
        let product = products.filter(v => v.productGroup === item && v.productLanguage === language)
        setSelectedProducts(product)
    }

    const updateProductsFromChild = async (id) => {
        const getProducts = await axios.get(`https://qrbites.co/api/customers/main-categories/products/id/${id}`);
        const products = getProducts.data
        setProducts(products)

        let product = products.filter(v => v.productGroup === selectedGroupButton && v.productLanguage === language)
        setSelectedProducts(product)
    }

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "auto",
        minWidth: "250px",
        overflow: 'auto',
        height: 'auto',
        maxHeight: "500px",
        bgcolor: 'background.paper',
        borderRadius: '20px',
        boxShadow: 24,
        p: 4,
    };

    const handleMainGroup = (e, item) => {
        let result = [...mainGroups];
        result = result.map((x) => {
            if (x.mainCategoriesID === item.mainCategoriesID) {
                x.mainCategoryName = e.target.value;
            }
            return x;
        });
        setMainGroups(result);
    }

    const handleSubGroup = (e, itemIndex) => {
        const { value } = e.target;
        setSelectedSubGroup((prevState) => {
            const updatedSubGroup = [...prevState];
            updatedSubGroup[0].ProductGroups[itemIndex] = value;
            return updatedSubGroup;
        });
    };

    const changeMainGroupDB = async () => {
        setMainGroupModal(false)
        try {
            const body = {
                id: customer.customerID,
                mainGroups: mainGroups,
                user: customer
            };
            const response = await axios.post(`https://qrbites.co/api/update-main-groups/`, JSON.stringify(body), {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            })
            const data = await response.data
            if (response.status !== 200) {
                if (data.message && (data.message === "Invalid Token" || data.message === "Token not found")) {
                    window.localStorage.removeItem("token")
                    navigate("/login")
                }
                return
            }
            const getCategories = await axios.get(`https://qrbites.co/api/customers/main-categories/id/${customer.customerID}`);
            const categories = getCategories.data
            setCategories(categories)

            let array = categories.filter(item => item.mainCategoryLanguages === language)
            setMainGroups(array)
        } catch (error) {
            console.log(error)
        }
    }

    const deleteSubGroup = async (item) => {
        setSubGroupModal(false)
        try {
            let selectedOne = selectedSubGroup[0].ProductGroups.filter(v => v === item)[0]
            let sameId = groupImages.find(item => item.groupName === selectedOne)?.sameGroup
            const body = {
                id: customer.customerID,
                categoryURL: selectedSubGroup[0].mainCategoryURL,
                subGroup: selectedSubGroup[0].ProductGroups.filter(v => v !== item),
                index: selectedSubGroup[0].ProductGroups.indexOf(item),
                sameId: sameId
            };
            const response = await axios.post(`https://qrbites.co/api/delete-sub-groups/`, JSON.stringify(body), {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            })
            const data = await response.data
            if (response.status !== 200) {
                if (data.message && (data.message === "Invalid Token" || data.message === "Token not found")) {
                    window.localStorage.removeItem("token")
                    navigate("/login")
                }
                return
            }
            const getCategories = await axios.get(`https://qrbites.co/api/customers/main-categories/id/${customer.customerID}`);
            const categories = getCategories.data
            setCategories(categories)

            let array = categories.filter(item => item.mainCategoryLanguages === language)
            setMainGroups(array)
            setSelectedSubGroup(array)
        } catch (error) {
            console.log(error)
        }
    }

    const changeSubGroupDB = async () => {
        setSubGroupModal(false)
        try {
            const getCategories = await axios.get(`https://qrbites.co/api/customers/main-categories/id/${customer.customerID}`);
            const temp = getCategories.data
            let array = []
            temp.forEach(item => {
                if (item.mainCategoryLanguages === language) {
                    array.push(item)
                }
            })
            let changedSubGroup = []
            const index = array.map(e => e.mainCategoriesID).indexOf(selectedSubGroup[0].mainCategoriesID);
            for (let i = 0; i < array[index].ProductGroups.length; i++) {
                for (let j = 0; j < selectedSubGroup[0].ProductGroups.length; j++) {
                    if (i === j && array[index].ProductGroups[i] !== selectedSubGroup[0].ProductGroups[j]) {
                        changedSubGroup.push({ old: array[index].ProductGroups[i], new: selectedSubGroup[0].ProductGroups[j] })
                    }
                }
            }

            let filteredProductsForChange = []
            for (let i = 0; i < products.length; i++) {
                if (products[i].productLanguage === language) {
                    filteredProductsForChange.push(products[i])
                }
            }
            let finalAdjustment = []
            changedSubGroup.forEach(item => {
                for (let i = 0; i < filteredProductsForChange.length; i++) {
                    if (item.old === filteredProductsForChange[i].productGroup) {
                        filteredProductsForChange[i].productGroup = item.new
                        finalAdjustment.push(filteredProductsForChange[i])
                    }
                }
            })
            editPicsPreview.forEach((item, index) => {
                const coverBase64Data = item.data.split(',')[1];
                editPicsPreview[index].data = coverBase64Data
            })
            const body = {
                id: customer.customerID,
                products: finalAdjustment,
                subGroup: selectedSubGroup,
                changedSubGroup: changedSubGroup,
                language: language,
                languages: languages,
                editPicsPreview: editPicsPreview
            };
            console.log(body)
            const response = await axios.post(`https://qrbites.co/api/update-sub-groups/`, JSON.stringify(body), {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            })
            const data = await response.data
            if (response.status !== 200) {
                if (data.message && (data.message === "Invalid Token" || data.message === "Token not found")) {
                    window.localStorage.removeItem("token")
                    navigate("/login")
                }
                return
            }
            const getCategories1 = await axios.get(`https://qrbites.co/api/customers/main-categories/id/${customer.customerID}`);
            const categories1 = getCategories1.data
            setCategories(categories1)

            const productImages = await axios.get(`https://qrbites.co/api/group-images/url/${customer.customerID}`);
            const imagesGroup = productImages.data
            setGroupImages(imagesGroup)

            let array1 = categories1.filter(item => item.mainCategoryLanguages === language)
            setMainGroups(array1)
            setSelectedSubGroup(array1)
        } catch (error) {
            console.log(error)
        }
    }

    const addNewSubGroup = async () => {
        try {
            let newGroup = selectedSubGroup[0].ProductGroups?.length ? selectedSubGroup[0].ProductGroups.join(',') : ""
            newGroup = newGroup ? newGroup + "," + newSubGroupVal : newSubGroupVal
            const base64Data = subLogoPreview ? subLogoPreview.split(',')[1] : "";

            const body = {
                id: customer.customerID,
                mainURL: selectedSubGroup[0].mainCategoryURL,
                newGroup: newGroup,
                image: base64Data,
                languages, languages
            };
            const response = await axios.post(`https://qrbites.co/api/add-sub-groups/`, JSON.stringify(body), {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            })
            const data = await response.data
            if (response.status !== 200) {
                if (data.message && (data.message === "Invalid Token" || data.message === "Token not found")) {
                    window.localStorage.removeItem("token")
                    navigate("/login")
                }
                return
            }
            setNewSubGroupVal("")
            setSubGroupModal(false)
            const getCategories = await axios.get(`https://qrbites.co/api/customers/main-categories/id/${customer.customerID}`);
            const categories = getCategories.data
            setCategories(categories)

            const productImages = await axios.get(`https://qrbites.co/api/group-images/url/${customer.customerID}`);
            const imagesGroup = productImages.data
            setGroupImages(imagesGroup)

            let array = categories.filter(item => item.mainCategoryLanguages === language)
            setMainGroups(array)
            setSelectedSubGroup(array)
        } catch (error) {
            console.log(error)
        }
    }

    const addNewMainGroup = async () => {
        try {
            const body = {
                id: customer.customerID,
                mainGroup: newMainGroupVal,
                mainUrl: newMainGroupUrl,
                languages: customer.customerLanguages
            };
            const response = await axios.post(`https://qrbites.co/api/add-main-groups/`, JSON.stringify(body), {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            })
            const data = await response.data
            if (response.status !== 200) {
                if (data.message && (data.message === "Invalid Token" || data.message === "Token not found")) {
                    window.localStorage.removeItem("token")
                    navigate("/login")
                }
                return
            }
            setNewMainGroupVal("")
            setNewMainGroupUrl("")
            setMainGroupModal(false)
            const getCategories = await axios.get(`https://qrbites.co/api/customers/main-categories/id/${customer.customerID}`);
            const categories = getCategories.data
            setCategories(categories)

            let array = categories.filter(item => item.mainCategoryLanguages === language)
            setMainGroups(array)
        } catch (error) {
            console.log(error)
        }
    }

    const deleteMainGroup = async (categoryUrl) => {
        try {
            const body = {
                id: customer.customerID,
                url: categoryUrl
            };
            const response = await axios.post(`https://qrbites.co/api/delete-main-groups/`, JSON.stringify(body), {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            })
            const data = await response.data
            if (response.status !== 200) {
                if (data.message && (data.message === "Invalid Token" || data.message === "Token not found")) {
                    window.localStorage.removeItem("token")
                    navigate("/login")
                }
                return
            }
            const getCategories = await axios.get(`https://qrbites.co/api/customers/main-categories/id/${customer.customerID}`);
            const categories = getCategories.data
            setCategories(categories)

            let array = categories.filter(item => item.mainCategoryLanguages === language)
            setMainGroups(array)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        const token = localStorage.getItem("token")
        const { user } = jwtDecode(token)

        const getCustomers = async () => {
            const getCustomer = await axios.get(`https://qrbites.co/api/customers/url/${user.customerURL}`);
            const customeru = getCustomer.data[0]

            if (customeru.customerQRCode) {
                const byteArray = new Uint8Array(customeru.customerQRCode.data);
                let binaryString = '';
                byteArray.forEach(byte => {
                    binaryString += String.fromCharCode(byte);
                });
                const base64Image = btoa(binaryString);
                setQrCodeCustomer(`data:image/jpeg;base64,${base64Image}`)
            }

            const productImages = await axios.get(`https://qrbites.co/api/group-images/url/${customeru.customerID}`);
            const imagesGroup = productImages.data
            setGroupImages(imagesGroup)
            setCustomer(customeru)
        }

        const getCategories = async () => {
            const getCategories = await axios.get(`https://qrbites.co/api/customers/main-categories/id/${user.customerID}`);
            const categories = getCategories.data

            setCategories(categories)
        }

        const getProducts = async () => {
            const getProducts = await axios.get(`https://qrbites.co/api/customers/main-categories/products/id/${user.customerID}`);
            const products = getProducts.data

            setProducts(products)
        }

        getCustomers()

        getCategories()
        getProducts()

    }, [])

    const changeNewMainGroupVal = (e) => {
        setNewMainGroupVal(e.target.value)
    }

    const changeNewMainGroupUrl = (e) => {
        setNewMainGroupUrl(e.target.value)
    }

    const changeNewSubGroupVal = (e) => {
        setNewSubGroupVal(e.target.value)
    }

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const isMobileSm = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (!customer.customerID) {
            return
        }
        setLanguages(customer.customerLanguages);
        setLanguage(customer.customerLanguages[0]);
        setCurrency(customer.currency)

        if (!localStorage.getItem("language")) {
            let lang = customer.customerLanguages.includes("EN") ? "EN" : customer.customerLanguages[0]
            setLanguage(lang)
            localStorage.setItem("language", lang)
        } else {
            setLanguage(localStorage.getItem("language"))
        }

        let array = []
        categories.forEach(item => {
            if (item.mainCategoryLanguages === localStorage.getItem("language")) {
                array.push(item)
            }
        })
        setMainGroups(array)
        setSelectedSubGroup(array)
        setBlaSel(array[0]?.ProductGroups ? [...array[0]?.ProductGroups] : [])
        setCoverPreview(`/static/qrbites-product-images/customer_cover_${customer.customerURL}_${customer.customerID}.png`)
        setLogoPreview(`/static/qrbites-product-images/customer_logo_${customer.customerURL}_${customer.customerID}.png`)
    }, [customer, categories]);

    useEffect(() => {

        let currentMultipleLanguages = []
        for (let i = 0; i < countryCodes.length; i++) {
            for (let j = 0; j < customer.customerLanguages.length; j++) {
                if (countryCodes[i].code == customer.customerLanguages[j]) {
                    currentMultipleLanguages.push(countryCodes[i].name)
                }
            }
        }
        setSelectedMultipleLanguage(currentMultipleLanguages)
    }, [customer.customerLanguages])

    const getChangedValues = (previousArray, currentArray) => {
        // Compare the previous and current arrays to find the changes
        const changedValues = [];
        for (let i = 0; i < previousArray.length; i++) {
            if (previousArray[i] !== currentArray[i]) {
                changedValues.push({ index: i, from: previousArray[i], to: currentArray[i] });
            }
        }
        return changedValues;
    };

    const onLogoButtonClick = () => {
        // `current` points to the mounted file input element
        inputFile.current.click();
        inputFile.current.value = ""
    };

    const onCoverButtonClick = () => {
        // `current` points to the mounted file input element
        inputFileCover.current.click();
        inputFileCover.current.value = ""
    };

    const onChangeCover = (e) => {
        let file = e.target.files[0]
        setCoverUpload(file)
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setCoverPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    }

    const [changeEditArray, setChangeEditArray] = useState([])
    const [editPicsPreview, setEditPicsPreview] = useState([])
    const onChangeEditPics = (e, index, item) => {
        let file = e.target.files[0];
        const newInputValues = [...selectedSubGroup[0]?.ProductGroups];
        newInputValues[index] = file;
        setChangeEditArray(newInputValues);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const newCoverPreviews = [...editPicsPreview];
                newCoverPreviews[index] = { data: reader.result, name: item };
                setEditPicsPreview(newCoverPreviews);
            };
            reader.readAsDataURL(file);
        }
    };

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250
            }
        },
        getContentAnchorEl: null,
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "center"
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "center"
        },
        variant: "menu"
    };
    const useStyles = makeStyles((theme) => ({
        select: {
            borderColor: "red",
        },
        formControl: {
            //   margin: theme.spacing(1),
            width: 300,
        },
        indeterminateColor: {
            color: "black"
        },
        selectAllText: {
            fontWeight: 500
        },
        selectedAll: {
            backgroundColor: "rgba(0, 0, 0, 0.08)",
            "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.08)"
            }
        }
    }));
    const listOfLanguages = countryCodes
    const [selectedMultipleLanguage, setSelectedMultipleLanguage] = useState([])
    const isAllSelected = listOfLanguages.length > 0 && selectedMultipleLanguage.length === listOfLanguages.length;
    const classes = useStyles();
    const handleMultipleLanguageChange = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
            setSelectedMultipleLanguage(selectedMultipleLanguage.length === listOfLanguages.length ? [] : listOfLanguages);
            return;
        }
        setSelectedMultipleLanguage(value);
    };



    const onChangeAvatar = (e) => {
        let file = e.target.files[0]
        setLogoUpload(file)
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setLogoPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    }

    const onChangeSubGroup = (e) => {
        let file = e.target.files[0]
        setSubGroupLogo(file)
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSubLogoPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    }



    return (
        <>
            <NavbarDashboard customer={customer} />
            {/* <div style={{display: "flex", alignItems: 'center', justifyContent: "center"}}> */}
            <Grid container xs={10} fullWidth sx={{ paddingTop: "81px", margin: "auto" }}>

                <Typography variant='h1' sx={{ WebkitTextStroke: "0.3px #000", paddingBottom: "20px" }}>Restaurants Info</Typography>
                <Grid container gap={5} sx={{ backgroundColor: "white", padding: isMobileSm ? "15px" : "50px", borderRadius: "2rem" }}>
                    <Grid item xs={isMobile ? 12 : 7}>

                        <Grid container sx={{ marginTop: "0", marginLeft: "0", justifyContent: isMobileSm ? "center" : "space-between" }} spacing={2}>
                            <Grid>
                                <Typography variant='h3'>Your current language is <span style={{ WebkitTextStroke: "0.3px #000" }}>
                                    {customer.customerLanguages && customer.customerLanguages[0] !== "" ?
                                        <Select
                                            value={language}
                                            id="demo-simple-select"
                                            onChange={onChangeLanguageDropdown}
                                            label="language"
                                        >
                                            {languages.map((item, index) => {
                                                console.log(item, index)
                                                return (
                                                    <MenuItem key={index} value={item}>{item}</MenuItem>
                                                )
                                            })}
                                        </Select> : <span>none found</span>}
                                </span></Typography>
                            </Grid>
                            <Grid alignSelf={"center"}>
                                <Button variant="contained" sx={{ padding: "4px 16px", fontSize: "17px", backgroundColor: "#366EF7", "&:hover": { backgroundColor: "black", color: "white" } }} onClick={() => handleOpenProductModal()} aria-label="upload picture" component="label"
                                    endIcon={<FastfoodIcon style={{ color: 'white', width: '16px' }} />}
                                >
                                    Add Product
                                </Button>
                                <Modal
                                    open={openAddProductModal}
                                    onClose={handleCloseProductModal}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={modalStyle}>
                                        <Typography id="modal-modal-title" variant="h6" textAlign={"center"} component="h2">Add New Product</Typography><hr />
                                        <Grid container spacing={2}>
                                            <Grid item xs={isMobile ? 12 : 6} fullWidth>
                                                <Typography variant='h3'>Name</Typography>
                                                <TextField
                                                    className='social-links'
                                                    onChange={(e) => setModalProductName(e.target.value)}
                                                    fullWidth
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"><img src={CafeIcon} style={{ width: "24px" }} /></InputAdornment>,
                                                    }}
                                                    id="1"
                                                    aria-describedby="1"
                                                    variant='outlined' />
                                            </Grid>
                                            <Grid item xs={isMobile ? 12 : 6}>
                                                <Typography variant='h3'>Price</Typography>
                                                <TextField
                                                    className='social-links'
                                                    onChange={(e) => setModalProductPrice(e.target.value)}
                                                    fullWidth
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"><span style={{ color: "black" }}>{customer.currency}</span></InputAdornment>,
                                                    }}
                                                    id="2"
                                                    aria-describedby="2"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='h3'>Ingredients</Typography>
                                                <TextField
                                                    className='social-links'
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"><img src={IngredientsIcon} style={{ width: "24px" }} /></InputAdornment>,
                                                    }}
                                                    onChange={(e) => setModalProductIngredients(e.target.value)}
                                                    fullWidth
                                                    id="3"
                                                    aria-describedby="3"
                                                />
                                            </Grid>
                                            <Grid item xs={isMobile ? 12 : 6}>
                                                <Typography variant='h3'>Main Category</Typography>
                                                <Select className='social-links' onChange={modalProductGroupOnChange} fullWidth id="4" aria-describedby="4">
                                                    {mainGroups.map(item => {
                                                        if (item.ProductGroups) {
                                                            return (
                                                                <MenuItem value={item}>{item.mainCategoryName}</MenuItem>
                                                            )
                                                        }
                                                    })}
                                                </Select>
                                            </Grid>
                                            <Grid item xs={isMobile ? 12 : 6}>
                                                <Typography variant='h3'>Product Group</Typography>
                                                <Select className='social-links' value={modalProductSubGroup} onChange={(e) => setModalProductSubGroup(e.target.value)} fullWidth id="5" aria-describedby="5">
                                                    {sub.map(item => (
                                                        <MenuItem value={item}>{item}</MenuItem>
                                                    ))}
                                                </Select>
                                            </Grid>
                                            <FormHelperText style={{ color: "red" }} sx={{ paddingX: "20px" }}>Whether the groups are in one language or another, it will create a replica of the product and place it in all
                                                groups, to save you the hassle of doing it manually
                                            </FormHelperText>
                                            <Grid item xs={12}>
                                                <Button fullWidth
                                                    variant="contained"
                                                    component="label"
                                                    sx={{ backgroundColor: "white", fontSize: "17px", border: "1px solid black", borderRadius: "20px", marginRight: "10px", padding: "5px 10px", textTransform: "none", color: "black", "&:hover": { backgroundColor: "black", color: "white" } }}
                                                >
                                                    Upload Product Image <UploadIcon fontSize='16px' />
                                                    <input
                                                        onChange={onChangeModalProductImage}
                                                        type="file"
                                                        ref={addProductRef}
                                                        hidden
                                                    />
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button variant="contained" sx={{ fontSize: "17px", backgroundColor: "#366EF7", "&:hover": { backgroundColor: "black", color: "white" } }} fullWidth onClick={submitAddNewProduct}>
                                                    Submit
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button variant='plain' sx={{ fontSize: "17px", "&:hover": { backgroundColor: "white", textDecoration: "underline" } }} fullWidth onClick={handleCloseProductModal}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Modal>
                            </Grid>
                        </Grid>

                        <Grid style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                            <div style={{ display: 'flex', justifyContent: isMobileSm ? "center" : "", alignItems: "center" }}>
                                <div className='scrollmenu'>
                                    {mainGroups.map((item) => {
                                        return (
                                            <>
                                                <Button sx={{ border: "1px solid black", minWidth: "unset", borderRadius: "20px", marginRight: "10px", padding: "5px 10px", textTransform: "none", color: "black" }} key={item.id} onClick={() => selectMainGroup(item.mainCategoryName)}>
                                                    <Typography sx={{
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textAlign: "left",
                                                        display: "block"
                                                    }}>{item.mainCategoryName}</Typography>
                                                </Button>
                                            </>
                                        )
                                    })}
                                </div>
                                {languages && languages[0] !== '' && <IconButton onClick={() => handleOpenMainGroupModal()} sx={{ backgroundColor: "white", height: "fit-content", border: "1px solid black", padding: "5px", "&:hover": { backgroundColor: "black" } }} aria-label="upload picture" component="label">
                                    <AddIcon sx={{ color: "black", fontSize: "16px", "&:hover": { color: "white" } }} />
                                </IconButton>}
                            </div>

                            <Modal
                                open={openMainGroupModal}
                                onClose={handleCloseMainGroupModal}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={modalStyle}>
                                    <Button sx={{ outline: "1px solid red" }} onClick={() => setMainGroupState(0)}>Add</Button>
                                    <Button sx={{ outline: "1px solid red" }} onClick={() => setMainGroupState(1)}>Edit</Button>
                                    <Button sx={{ outline: "1px solid red" }} onClick={() => setMainGroupState(2)}>Delete</Button>
                                    {mainGroupState == 0 &&
                                        <>
                                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                                Add another main group
                                            </Typography>
                                            <label>Name</label>
                                            <Input value={newMainGroupVal} onChange={(e) => changeNewMainGroupVal(e)} /><br />
                                            <label>Url</label>
                                            <Input value={newMainGroupUrl} onChange={(e) => changeNewMainGroupUrl(e)} /><br />
                                            <Button onClick={() => addNewMainGroup()}>ADD</Button>
                                        </>
                                    }
                                    {mainGroupState == 1 &&
                                        <>
                                            {mainGroups.map((item) => {
                                                return (
                                                    <div>
                                                        <Input key={item.id} value={item.mainCategoryName} onChange={(e) => handleMainGroup(e, item)} />
                                                    </div>
                                                )
                                            })}
                                            <Button onClick={() => changeMainGroupDB()}>SAVE</Button>

                                        </>
                                    }
                                    {mainGroupState == 2 &&
                                        <>
                                            {mainGroups.map((item) => {
                                                return (
                                                    <div>
                                                        <span key={item.id}>{item.mainCategoryName}</span>
                                                        <Button onClick={() => deleteMainGroup(item.mainCategoryURL)}>X</Button>
                                                    </div>
                                                )
                                            })}
                                        </>
                                    }
                                </Box>
                            </Modal>

                            <Divider sx={{ marginY: "15px" }} />

                            <div style={{ display: 'flex', justifyContent: isMobileSm ? "center" : "", alignItems: "center" }}>
                                <div className='scrollmenu'>
                                    {selectedSubGroup[0]?.ProductGroups?.length ? selectedSubGroup[0]?.ProductGroups.map((item) => {
                                        return (
                                            <>
                                                <Button sx={{ border: "1px solid black", minWidth: "unset", borderRadius: "20px", marginRight: "10px", padding: "5px 10px", textTransform: "none", color: "black" }} key={item} onClick={() => selectProductGroup(item)}>
                                                    <Typography sx={{
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textAlign: "left",
                                                        display: "block"
                                                    }}>{item}</Typography>
                                                </Button>
                                            </>
                                        )
                                    }) : ""}
                                </div>

                                {languages && languages[0] !== '' && <IconButton onClick={() => handleOpenSubGroupModal()} sx={{ backgroundColor: "white", height: "fit-content", border: "1px solid black", padding: "5px", "&:hover": { backgroundColor: "black" } }} aria-label="upload picture" component="label">
                                    <AddIcon sx={{ color: "black", fontSize: "16px", "&:hover": { color: "white" } }} />
                                </IconButton>}
                            </div>

                            <Modal
                                disableScrollLock={true}
                                open={openSubGroupModal}
                                onClose={handleCloseSubGroupModal}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={modalStyle}>
                                    <Button sx={{ outline: "1px solid red" }} onClick={() => setSubGroupState(0)}>Add</Button>
                                    <Button sx={{ outline: "1px solid red" }} onClick={() => setSubGroupState(1)}>Edit</Button>
                                    <Button sx={{ outline: "1px solid red" }} onClick={() => setSubGroupState(2)}>Delete</Button>
                                    {subGroupState == 0 &&
                                        <>
                                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                                Add another Sub group
                                            </Typography>
                                            <label>Name</label>
                                            <Input value={newSubGroupVal} onChange={(e) => changeNewSubGroupVal(e)} /><br />
                                            <Input type='file' id='file' ref={subGroupFile} onChange={onChangeSubGroup}></Input>
                                            <img width="200px" style={{ maxWidth: "200px" }} src={subLogoPreview} />
                                            <Button onClick={() => addNewSubGroup()}>ADD</Button>
                                        </>
                                    }
                                    {subGroupState == 1 &&
                                        <>
                                            {selectedSubGroup[0]?.ProductGroups.map((item, index) => {
                                                let find = blaSel[index]
                                                let correctImage = groupImages.find(it => it.groupName === find)
                                                return (
                                                    <div key={index}>
                                                        <Input value={item} onChange={(e) => handleSubGroup(e, index)} />
                                                        <img width="200px" style={{ maxWidth: "200px" }}
                                                            src={`/static/qrbites-product-images/sub_group_image_${correctImage?.sameGroup}.png`} />
                                                        <input
                                                            type="file"
                                                            name="file"
                                                            onChange={(e) => onChangeEditPics(e, index, item)}
                                                        />
                                                    </div>
                                                )


                                            })}
                                            <Button onClick={() => changeSubGroupDB()}>SAVE</Button>

                                        </>
                                    }
                                    {subGroupState == 2 &&
                                        <>
                                            {selectedSubGroup[0]?.ProductGroups.length && selectedSubGroup[0]?.ProductGroups?.map((item) => {
                                                return (
                                                    <div>
                                                        <span key={item}>{item}</span>
                                                        <Button onClick={() => deleteSubGroup(item)}>X</Button>
                                                    </div>
                                                )
                                            })}
                                        </>
                                    }
                                </Box>
                            </Modal>
                        </Grid>

                        {selectedProducts.length != 0 ?
                            selectedProducts.map((item) =>
                                <EditProduct key={item.productID} product={{ item, currency: currencies.find(item => item.cc === customer.currency).symbol, mainGroups, categories, notifyParent: updateProductsFromChild }} />) :
                            <div style={{ display: "flex", height: "40vh", alignItems: "center", justifyContent: "center" }}>
                                <Typography variant='h2' color='#959595'>This list is empty.</Typography>
                            </div>}

                    </Grid>
                    <Grid item xs={isMobile ? 12 : 4} sx={{ marginLeft: isMobileSm ? "0" : "30px" }}>
                        <Grid container gap={3}>
                            <Grid item xs={12} sx={{ display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: 'center' }} >

                                <Grid container>
                                    <Grid item xs={8}>
                                        <Typography variant='h3' >Restaurant Information</Typography>
                                        <Typography variant='body1'><a href={`https://app.qrbites.co/${customer.customerURL}`}>app.qrbites.co/{customer.customerURL}</a></Typography>
                                    </Grid>

                                    <Grid item xs={4} sx={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                                        <div className='qr-code-icon' onClick={handleOpenQrCode}>
                                            <img src={QRIcon} />
                                        </div>
                                    </Grid>
                                </Grid>

                                <Modal
                                    open={openQrCode}
                                    onClose={handleCloseQrCode}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={modalStyle} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                        <canvas id="qr-canvas" style={{ display: "none" }} ref={qrCodeCanvas}></canvas>
                                        <img width="200px" src={qrCodeCustomer} />
                                        {!qrCodeCustomer && <Button onClick={generateQrCode}>Generate QR Code</Button>}
                                        <Button variant="contained" sx={{ padding: "4px 16px", fontSize: "17px", backgroundColor: "#366EF7", "&:hover": { backgroundColor: "black", color: "white" } }} onClick={() => download(qrCodeCustomer)} aria-label="upload picture" component="label"
                                            
                                        >Download QR Code</Button>
                                    </Box>
                                </Modal>


                                <img onClick={onCoverButtonClick} className='cover-dashboard'
                                    src={coverPreview || 'https://qrbites.co/wp-content/uploads/2023/06/DefaultImage.png'} />
                                <Button
                                    variant="contained"
                                    style={{ display: 'none' }}
                                    component="label"
                                ><Input type='file' id='file' ref={inputFileCover} onChange={onChangeCover}></Input></Button>

                                <img onClick={onLogoButtonClick} class="logo-dashboard-2"
                                    src={logoPreview || 'https://qrbites.co/wp-content/uploads/2023/06/DefaultImage.png'} alt="" />
                                <Button
                                    variant="contained"
                                    style={{ display: 'none' }}
                                    component="label"
                                ><Input type='file' id='file' ref={inputFile} onChange={onChangeAvatar}></Input></Button>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant='h3' >Name</Typography>
                                <TextField
                                    className='social-links'
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><img src={RestaurantIcon} style={{ width: "24px" }} /></InputAdornment>,
                                    }}
                                    name="customerName"
                                    value={customer.customerName}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant='h3'>Phone</Typography>
                                <TextField
                                    className='social-links'
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><img src={PhoneIcon} style={{ width: "24px" }} /></InputAdornment>,
                                    }}
                                    name="customerPhoneNumber"
                                    onChange={handleChange}
                                    value={customer.customerPhoneNumber}
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant='h3'>Wifi Password</Typography>
                                <TextField
                                    className='social-links'
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><img src={WifiIcon} style={{ width: "24px" }} /></InputAdornment>,
                                    }}
                                    name="customerWifiPassword"
                                    onChange={handleChange}
                                    value={customer.customerWifiPassword}
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant='h3'>Currency</Typography>
                                <Select
                                    className='social-links'
                                    value={currency}
                                    id="demo-simple-select"
                                    fullWidth
                                    onChange={onCurrencyChange}
                                    label="currency"
                                >
                                    {currencies.map(item => {
                                        return (
                                            <MenuItem key={item.cc} value={item.cc}>{item.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant='h3'>Languages</Typography>
                                <FormControl className="social-links" >
                                    <Select
                                        labelId="mutiple-select-label"
                                        multiple
                                        placeholder='Multiple Select'
                                        value={selectedMultipleLanguage}
                                        onChange={handleMultipleLanguageChange}
                                        renderValue={(selectedMultipleLanguage) => (
                                            selectedMultipleLanguage.join(", ")
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem
                                            value="all"
                                            classes={{
                                                root: isAllSelected ? classes.selectedAll : ""
                                            }}
                                        >
                                            <ListItemIcon >
                                                <Checkbox
                                                    classes={{ indeterminate: classes.indeterminateColor }}
                                                    checked={isAllSelected}
                                                    indeterminate={
                                                        selectedMultipleLanguage.length > 0 && selectedMultipleLanguage.length < listOfLanguages.length
                                                    }
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                classes={{ primary: classes.selectAllText }}
                                                primary="Select All"
                                            />
                                        </MenuItem>
                                        {listOfLanguages.map((option) => (
                                            <MenuItem key={option.name} value={option.name} >
                                                <ListItemIcon>
                                                    <Checkbox checked={selectedMultipleLanguage.indexOf(option.name) > -1} />
                                                </ListItemIcon>
                                                <ListItemText primary={option.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant='h3'>Instagram Account</Typography>
                                <TextField
                                    className='social-links'
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><img src={InstaIcon} style={{ width: "24px" }} /></InputAdornment>,
                                    }}
                                    name="customerInstagram"
                                    onChange={handleChange}
                                    value={customer.customerInstagram}
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant='h3'>Facebook Account</Typography>
                                <TextField
                                    className='social-links'
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><img src={FbIcon} style={{ width: "24px" }} /></InputAdornment>,
                                    }}
                                    name="customerFacebook"
                                    onChange={handleChange}
                                    value={customer.customerFacebook}
                                    variant="outlined"
                                />
                            </Grid>

                            <Button variant='contained' fullWidth={isMobile} onClick={handleSubmit} className='save-btn'>
                                Save
                            </Button>
                        </Grid>


                    </Grid>
                </Grid>

                <Grid container sx={{ paddingY: "30px" }} textAlign={isMobile ? "center" : "left"} fullWidth>
                    <Grid item xs={12}>
                        <Typography variant='h3'>Powered by <a href='https://codelii.com' style={{ color: "#000" }}>Codelii</a></Typography>
                    </Grid>
                </Grid>

            </Grid>
            {/* </div> */}
        </>

        // <Grid container spacing={2}>
        // </Grid>
    )
}

export default Dashboard
