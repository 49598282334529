import React, { useEffect, useState } from 'react'
import axios from 'axios';
import {useNavigate} from "react-router-dom"
import { Box, Container, Grid, Input, TextField, Typography, Button, Divider, Link, Alert, Collapse, Modal } from '@mui/material';
import "./Login.css";
import  LoginIMG from "../../Assets/Group3.png";
import  {ReactComponent as FacebookLogo} from "../../Assets/path1394.svg";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';


const Login = () => {
    const [loginForm, setLoginForm] = useState({ email: "", password: "" });
    const navigate = useNavigate();
    const [openError, setOpenError] = useState(false);
    const [alertMessage, setAlertMessage] = useState("")
    const [success, setSuccess] = useState(false)
    const [isOpenSuccess, setIsOpenSuccess] = useState(false)
    const handleOpen = () => setIsOpenSuccess(true);
    const handleClose = () => setIsOpenSuccess(false);
    const handleChange = (e) => {
      const name = e.target.name;
      const value = e.target.value;
      setLoginForm({ ...loginForm, [name]: value });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsOpenSuccess(true)
      setSuccess(false)
      try {
        const body = { email: loginForm.email, password: loginForm.password };
        const response = await axios.post("https://qrbites.co/api/login", JSON.stringify(body), {
          headers: {
            "Content-Type": "application/json"
          }
        })
        const data = await response.data;
        if (data.error) {
          setAlertMessage(data.error)
          setOpenError(true)
          setSuccess(false)
          setIsOpenSuccess(false)
          return
        }
        localStorage.setItem('token', data.token);
        setAlertMessage("")
        setOpenError(false)
        setSuccess(true)
        setTimeout(() => {
          setIsOpenSuccess(false)
          navigate("/dashboard")
        }, 500);
      } catch (error) {
        setSuccess(false)
        setIsOpenSuccess(false)
        console.log(error)
      }
    }
    return (
    <Grid
      container
      sx={{
        backgroundColor:"white",
        height: { xs: 'auto', md: '100vh' },
        width: '100vw',
        overflow: 'hidden',
        margin: 0,
      }}
    >


<Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        xs={12}
        md={6}
        sx={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: { xs: 'start',sm:"center", md: 'center' },
          height: { xs: '100vh', md: '100vh' },
          px: { xs: '2rem', md: '0px' },
        }}
      >
        
        <Box sx={{display:"flex", flexDirection:"column", width: { xs: '100%', sm:"80%",md: "60%" }}}>
        <Typography sx={{fontSize:"3rem", fontWeight:"900", WebkitTextStrokeWidth:"1px"}}>Log In</Typography> 
       <TextField
                   label="Email"
                   type="email" 
                   name="email" 
                   value={loginForm.email} 
                   onChange={handleChange}
                   inputProps={{ style: { fontSize: '1.5rem', height: "100%" } }}
                   InputProps={{ style: { fontSize: '1.5rem', height: "100%" } }}
                   sx={{marginTop:"3rem", height: "3rem"}}
                 />
                 <TextField
                   name="password"
                   label="Password"
                   type="password"
                   value={loginForm.password} 
                   onChange={handleChange}
                   sx={{marginTop:"3rem", height:"3rem"}}
                   InputProps={{ style: { fontSize: '1.5rem', height: "100%" } }}

                //    inputProps={{ style: { fontSize: '1.5rem', lineHeight:"2rem" } }}
                   // InputLabelProps={{ style: { fontSize: '1.1rem' } }}
                 /><br/>
                 <Collapse in={openError}>
                    <Alert variant="outlined" style={{fontSize: "16px"}} severity="error">{alertMessage}</Alert>
                 </Collapse>
                 <Link sx={{textDecoration:"none", cursor: "pointer", textSize:"1rem",alighSelf:"flex-end", textAlign:"right", marginTop:"1rem"}}>Forgot your password?</Link>

                 <Button onClick={handleSubmit} className="btn-login"  variant="contained" sx={{marginTop:"4rem",width:"10rem",backgroundColor:"black", alighSelf:"flex-end !important", textAlign:"right  !important"}}>Login</Button>

        </Box>
<Box sx={{display: "flex",
    alignItems: "center",position:"absolute", bottom:"2rem", left:"2rem"}}>

<TwitterIcon style={{cursor: "pointer"}}/>
<InstagramIcon style={{cursor: "pointer", margin:" 0 30px"}}/>
<FacebookIcon style={{cursor: "pointer"}}/>

</Box>
        
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        xs={0}
        md={6}
        sx={{ 
        display: { xs: 'none', md: 'block' },
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: { xs: 'start', md: 'center' },
          height: { xs: '0vh', md: '100vh' },
          
        }}
      >
        <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        margin={"2%"}
        xs={12}
        sx={{ 
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: "center",
          bgcolor: 'primary.secondary',
          height:"fit-container",
          width:"96%",
          height:"96%",
          borderRadius:"20px"
        
        }}
      >
        <Modal onClose={handleClose} open={isOpenSuccess}>
                  <Box style={{position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: "transparent",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "4px"}}>

            <div className={`circle-loader ${!success ? "" : "load-complete"}`}>
              <div className={"checkmark draw"} style={{display: success ? "block" : "none"}}></div>
            </div>
            </Box>
        </Modal>
       
<img src={LoginIMG}/>        
      </Grid>
      </Grid>

      
    </Grid>
    )
}

export default Login