import React, { useEffect } from "react"
import { Grid, CircularProgress } from "@mui/material"



export const RedirectMain = () => {
    useEffect(() => {
        window.location.href = "https://qrbites.co"
    }, [])

    return (        
              <Grid container style={{
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }} direction="column" alignItems="center">
        
                <img src={"https://qrbites.co/wp-content/uploads/2023/05/Asset-1@3x.png"} alt="Logo" style={{
                  width: 200,
                }} />
                <div style={{
                  position: 'relative',
                  width: 45,
                  height: 45
                }}>
                  <CircularProgress style={{color: "#ff0000"}} size={43} thickness={2}/>
                  <div style={{
                    position: 'absolute',
                    top: "50%",
                    left: "49%",
                    transform: 'translate(-50%, -49%)'
                  }}>
                    <CircularProgress style={{animationDirection: "reverse", color: '#346ef7'}} size={50} thickness={2}/>
                  </div>
                </div>
        
                <hr />
                <p className='loading-elipsis'>
                Redirecting to Main Domain
                </p>
        
              </Grid>
        
            )
}

