import { Button, Grid, Icon, AppBar, Hidden, Toolbar, useMediaQuery, IconButton, Dialog, Drawer, Menu, List, ListItem, ListItemIcon, ListItemText, ListItemButton, Divider } from '@mui/material'
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import { useTheme } from "@mui/styles"
import React, { useEffect, useState } from 'react'
import LogoutIcon from '@mui/icons-material/Logout';
import { makeStyles } from "@mui/styles"
import Logo from '../../Assets/logo.png'
import "./NavbarDashboard.css"
import ProfileIcon from './../../Assets/profile.svg'
import PaymentIcon from './../../Assets/payment.svg'
import HelpIcon from "./../../Assets/help.svg"
import { useNavigate } from "react-router-dom"



const NavbarDashboard = (props) => {
  // Modal
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [paidUntil, setPaidUntil] = useState("")
  const [alreadyPlayed, setAlreadyPlayed] = useState(false)

  const logUserOut = () => {
    window.localStorage.removeItem("token")
    navigate("/login")
  }
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleScroll = () => {
    const position = window.pageYOffset;
      if (position) {
        setAlreadyPlayed(true)
      } else {
        setAlreadyPlayed(false)
    }
  };

  useEffect(() => {
    if (props.customer.paidFrom) {
      let date = props.customer.paidFrom.split("/")
      let calculatedDate = date[2]
      let parsedDate = parseInt(calculatedDate)
      setPaidUntil(`${date[0]}/${date[1]}/${parsedDate + 1}`)
    }
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [props])

  const [openNav, setOpenNav] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleOpenNav = () => {
    setOpen(true);
  };

  const handleCloseNav = () => {
    setOpen(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <AppBar fullWidth position="fixed" className={alreadyPlayed ? "nav-unscrolled" : "nav-scrolled"}
      style={{
        maxHeight: "70px",
        width: "-webkit-fill-available",
        background: "#F2F2F2",
        boxShadow: "none",
        paddingRight: "0",
        border: alreadyPlayed ? "1px solid gray" : "none",
        borderRadius: "5px"
      }}>
      <Dialog open={open} onClose={handleClose}>
        <Modal 
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className='payment-modal' >
            <Typography id="modal-modal-title" variant="h2" component="h2">
              Payment Information
            </Typography>

            <Grid container className='payment-modal-details'>
              <Typography id="modal-modal-description" variant="h3" sx={{ mt: 2, WebkitTextStroke: "0.3px #000" }}>
                Paid From: <span style={{ WebkitTextStroke: 0, fontSize: "16px", marginLeft: "1rem" }}>{props.customer.paidFrom}</span>
              </Typography>

              <Typography id="modal-modal-description" variant="h3" sx={{ mt: 2, WebkitTextStroke: "0.3px #000" }}>
                Paid Until: <span style={{ WebkitTextStroke: 0, fontSize: "16px", marginLeft: "1rem" }}>{paidUntil}</span>
              </Typography>
            </Grid>

            <Typography id="modal-modal-description" variant="h3" sx={{ mt: 5 }}>
              To prolong the paid period please contact us.
            </Typography>

            <Button sx={{
              backgroundColor: "black", height: "2.5rem", padding: "1rem", fontSize: "16px", lineHeight: 1.5, color: "#fff", marginTop: "1rem",
              '&:hover': { backgroundColor: "black" }
            }} >Contact Us</Button>
          </Box>
        </Modal>
      </Dialog>
      <Toolbar style={{ padding: 0 }}>

        <Grid fullWidth sx={{ margin: "auto" }} container justifyContent={"space-between"} xs={10} alignItems="center" spacing={2}>
          <Grid item style={{ padding: '0', marginTop: "4px" }} xs={6} sm={2}>
            <img src={Logo} style={{ width: '130px' }} alt="Logo" />
          </Grid>

          {isMobile ? (
            <>
              <Grid textAlign={"right"} style={{ padding: '0' }} item xs={6}>
                <IconButton onClick={handleDrawerOpen}>
                  <MenuIcon />
                </IconButton>
                <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
                  <List>
                    <ListItemButton button onClick={handleOpen}>
                      <ListItemIcon style={{ minWidth: "30px" }}>
                        <img src={PaymentIcon} style={{ width: '16px' }} alt="Payment" />
                      </ListItemIcon>
                      <ListItemText primary="Payment" />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton button onClick={logUserOut}>
                      <ListItemIcon style={{ minWidth: "30px" }}>
                        <LogoutIcon style={{ color: 'black', width: '16px' }} />
                      </ListItemIcon>
                      <ListItemText primary="Logout" />
                    </ListItemButton>
                  </List>
                </Drawer>
              </Grid>
            </>
          ) : (
            <Grid item xs={10} style={{ padding: '0' }} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Grid container justifyContent="flex-end" alignItems="center">
                <IconButton onClick={handleMenuOpen}>
                  <img src={HelpIcon} style={{ width: '20px', marginRight: "1rem" }} alt="Help" />
                </IconButton>
                <Menu 
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                </Menu>

                <Button
                  onClick={() => handleOpen()}
                  variant="contained"
                  sx={{ border: "1px solid black", backgroundColor: "#F2F2F2", height: "2.5rem", padding: "1rem", fontSize: "16px", lineHeight: 1.5, color: "black", marginX: "1rem" }}
                  className="payment-btn"
                  startIcon={<img src={PaymentIcon} style={{ color: 'white', width: '16px' }} alt="Payment" />}
                >
                  Payment
                </Button>

                <Button
                  onClick={logUserOut}
                  variant="contained"
                  className="payment-btn"
                  sx={{ border: "1px solid black", backgroundColor: "#F2F2F2", height: "2.5rem", padding: "1rem", fontSize: "16px", lineHeight: 1.5, color: "black", marginX: "1rem" }}
                  startIcon={<LogoutIcon style={{ color: 'black', width: '16px' }} />}
                >
                  Logout
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default NavbarDashboard
