import React, {useEffect, useState} from 'react'
import { Box, Container, Grid, Input, TextField, Typography, Button, Divider } from '@mui/material';
import image from '../../Assets/burger_img.jpg';
import CyrillicToLatin from '../../Functions/CyrillicToLatin/CyrillicToLatin';
import RestaurantInfoWithoutSearch from '../RestauranInfo/RestaurantInfoWithourSearch'
import SingleProduct from '../SingleProduct/SingleProduct';

const MainCategoryCard = (props) => {
  return (
    <>
    <div style={{color: "white", padding: "10px 20px"}}>
      <a onClick={() => props.openProductsForUse(`${window.location.pathname}/${decodeURI(props.url)}`, props.name)} style={{ textDecoration: "none" }}>
      <Grid container sx={{width:"100%",height:"250px", borderRadius:"20px", backgroundPosition:"center center", position: "relative", overflow: "hidden", backgroundSize:"cover", backgroundRepeat:"no-repeat", 
      }}> 
      <img loading='lazy' width="100%" height="100%" style={{zIndex: 1, position: "absolute"}} src={`https://app.qrbites.co/static/qrbites-product-images/sub_group_image_${props.image?.sameGroup}.png`}/>
          <div style={{backgroundColor: "rgba(0, 0, 0, 0.4)", zIndex: 2, width:"100%", height:"250px", borderRadius:"20px", display: "flex",  alignItems:"center", textAlign: "center", justifyContent:"center",}}>
              <Typography variant="h1" sx={{color:"white", fontWeight:"900", zIndex: 3,  WebkitTextStroke: "0.3px white", fontSize: "26px"}}>{props.name}</Typography>
          </div>
          
      </Grid>
      </a>
    </div>

    </>
  )
}

export default MainCategoryCard
